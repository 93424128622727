@import 'src/styles/common';

.portal {
    z-index: var(--z-over-over);
}

.snackbar {
    @include reset;

    align-items: center;
    background-color: var(--snackbar-background-color);
    border-radius: var(--snackbar-border-radius);
    top: var(--spacing-md);
    color: var(--snackbar-color);
    display: flex;
    left: var(--snackbar-horizontal-offset);
    margin: var(--snackbar-vertical-offset) auto 0;
    padding: var(--spacing-default) var(--spacing-default);
    position: fixed;
    right: var(--snackbar-horizontal-offset);
    max-width: var(--snackbar-max-width);
    transition: all var(--transition-duration) var(--transition-curve-default) var(--transition-duration);
    z-index: var(--z-over-over);
    border-radius: var(--border-radius-md);

    &.accept .button {
        color: var(--snackbar-color-accept);
    }

    &.warning {
        border: 3px solid var(--snackbar-color-warning);

        .button {
            color: var(--snackbar-color-warning);
        }
    }

    &.error {
        border: 3px solid var(--snackbar-color-error);

        .button {
            color: var(--snackbar-color-error);
        }
    }

    &.cancel .button {
        color: var(--snackbar-color-cancel);
    }

    &:not(.active) {
        transform: translateY(-200%);
    }

    &.active {
        transform: translateY(0%);
    }
}

.label {
    @include typo-small;
    flex-grow: 1;
}

.button {
    margin: calc(-1 * var(--snackbar-vertical-offset) / 2) calc(-1 * var(--snackbar-horizontal-offset) / 2)
        calc(-1 * var(--snackbar-vertical-offset) / 2) var(--snackbar-button-offset);
    min-width: inherit;
}
