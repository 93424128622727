.tabs {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.spacing {
  flex: 1;
}

.actions {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.idleButtonPaused {
  background: var(--color-removing) !important;
  color: var(--color-text-invert) !important;
  font-weight: bold;
}
