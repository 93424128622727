@import '../common';

.wrapper {
    @include reset;

    align-items: center;
    display: flex;
    height: calc(100 * var(--vh));
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: var(--z-over-over);
}

.dialog {
    border-radius: var(--dialog-border-radius);
    box-shadow: 0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22);
    display: flex;
    flex-direction: column;
    overflow: inherit;
    transition-delay: var(--transition-delay);
    z-index: var(--z-over-over);
    margin: 0 auto;
    
    &:not(.customInitialPosition) {
        opacity: 0;
        max-width: 96vw;
        transform: translateY(calc(-1 * var(--dialog-translate-y)));
        transition: opacity var(--transition-duration) var(--transition-curve-default),
            transform var(--transition-duration) var(--transition-curve-default),
            height 1s 0.4s;
    }

    &.customInitialPosition {
        transition: top var(--transition-duration) var(--transition-curve-default),
            right var(--transition-duration) var(--transition-curve-default),
            bottom var(--transition-duration) var(--transition-curve-default),
            left var(--transition-duration) var(--transition-curve-default);
    }

    &.active:not(.customInitialPosition) {
        opacity: 1;
        transform: translateY(0%);
    }

    &.active.customInitialPosition {
        opacity: 1;
        top: var(--spacing-default) !important;
        left: var(--spacing-default) !important;
        right: var(--spacing-default) !important;
        bottom: var(--spacing-default) !important;
    }
}

.xs:not(.customInitialPosition) {
    max-width: 96vw;
    width: 100%;

    @media (--break-mobile) {
        max-width: 400px;
    }

    @media (--break-tablet) {
        max-width: 400px;
        min-width: 400px;
    }
}

.small:not(.customInitialPosition) {
    max-width: 96vw;
    width: 100%;

    @media (--break-mobile) {
        max-width: 400px;
    }

    @media (--break-tablet) {
        max-width: 500px;
        min-width: 400px;
        width: auto;
    }

    @media (--break-tablet-horizontal) {
        max-width: 600px;
        min-width: 400px;
    }
}

.normal:not(.customInitialPosition) {
    max-width: 500px;

    @media (--viewport-mobile-xs) {
        width: 96vw;
    }
}

.large:not(.customInitialPosition) {
    width: 96vw;
}

.fullscreen {
    width: 96vw;

    @media (--viewport-mobile-xs) {
        border-radius: 0;
        max-height: calc(100 * var(--vh));
        max-width: 100vw;
        min-height: calc(100 * var(--vh));
        width: 100vw;
    }
}

.title {
    color: var(--dialog-color-title);
    flex-grow: 0;
    font-size: calc(2 * var(--unit));
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 1;
    margin: 0 0 calc(1.6 * var(--unit));
}

.body {
    max-height: calc(96 * var(--vh));
    background-color: var(--dialog-color-header-bg);
    color: var(--color-text);
    flex-grow: 2;
    opacity: 0;
    transition: opacity 0.4s;
    height: 100%;
    display: flex;
    flex-flow: column;

    & p {
        font-size: calc(1.4 * var(--unit));
        font-weight: 400;
        letter-spacing: 0;
        line-height: calc(2.4 * var(--unit));
        margin: 0;
    }

    .active & {
        opacity: 1;
    }
}

.navigation {
    flex-grow: 0;
    padding: var(--dialog-navigation-padding);
    text-align: right;
}

.button {
    margin-left: var(--dialog-navigation-padding);
    min-width: 0;
    padding-left: var(--dialog-navigation-padding);
    padding-right: var(--dialog-navigation-padding);
}
