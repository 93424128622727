@import '../common';

.footer {
  width: 100%;
  background-color: var(--color-bc-creamy);
  display: flex;
  position: relative;
  min-height: calc(10 * var(--unit));
  align-items: center;
}

.settings {
  background-color: var(--color-bg);
  border-radius: var(--border-radius-sm);
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  padding: var(--unit);
  margin: var(--spacing-md);
  z-index: var(--z-over-over);
}

.mode {
  border-radius: var(--border-radius-sm);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-sm);
  position: relative;
  font-size: var(--icon-md);
  color: var(--color-text-light);
  cursor: pointer;

  &:not(:last-child) {
    margin-right: var(--spacing-sm);
  }

  &.active {
    background-color: var(--color-text-creamy-2);
    color: var(--color-accent);
  }
}

.badge {
  @include typo-small;

  color: var(--color-text-creamy);
  display: inline-block;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: var(--spacing-md);
  min-height: var(--spacing-md);
  background-color: var(--color-accent);
  border-radius: var(--spacing-md);
  position: absolute;
  top: 0;
  right: 0;
  margin-right: calc(-1 * var(--spacing-sm));
  margin-top: calc(-1 * var(--spacing-sm));
}

.appLogo {
  position: absolute;
  width: calc(12 * var(--unit));
  height: var(--spacing-lg);
  margin: 0;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

:global(.dark) {
  .badge {
    color: var(--color-text);
  }
}
