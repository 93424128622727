@import 'src/styles/common';

.container {
    width: 100%;
}

.title {
    width: 100%;
    padding-bottom: var(--spacing-default);
    margin: 0;
}

.content {
}

.container {
    .toggle.closeIcon:not([disabled]) {
        position: absolute;
        top: 10px;
        right: 10px;
        color: var(--color-text-invert);
    }
}

.closeIcon {
    position: absolute;
    top: 0;
    right: 0;
}

.actions {
    width: 100%;
    text-align: right;
    padding-top: var(--spacing-default);
}
