@import '../common';

.active {
    color: var(--color-text);
    background-color: var(--color-accent-light);
}

.container {
    align-self: center;
    padding: 0 var(--spacing-sm);

    color: var(--color-table-text-summary);
    font-size: var(--button-floating-mini-font-size);
    line-height: 3.3rem;
    display: flex;
    align-items: center;

    > span {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: calc(2.8 * var(--unit));
        height: calc(2.8 * var(--unit));
        transition: background-color var(--transition-2), color var(--transition-2);
    }
}
