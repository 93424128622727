.container {
    position: absolute;
    right: calc(-1 * var(--unit));
    top:  calc(-1 * var(--unit));
}

.imageBox {
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-left-radius: 50%;
    background: var(--color-logo-bg);
    overflow: hidden;
    width: calc(12 * var(--unit));
    height: calc(12 * var(--unit));
    min-width: calc(12 * var(--unit));
    min-height: calc(12 * var(--unit));

    &.noBackground {
        background: none;
    }
}

.logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}