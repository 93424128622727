@import '../utils/typography';

.list {
    list-style-type: none;
    padding: var(--spacing-default);
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    .tab {
        @include typo-normal;

        border-bottom: 1px solid transparent;
        transition: border-bottom 0.3s ease-in-out, font-weight 0.3s ease-in-out;
        cursor: pointer;
        
        &:not(:last-child) {
            margin-right: var(--spacing-lg);
        }

        &.selected {
            border-bottom: 1px solid var(--color-accent);
            display: inline-table;
            font-weight: bold;
        }
    }

    li {
        box-sizing: border-box;
        flex: 1;
        padding: 0 10px;
        text-align: center;

        label {
            transition: all 0.3s ease-in-out;
            color: var(--color-text-subdued);
            padding: 5px auto;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            white-space: nowrap;
            -webkit-touch-callout: none;

            &.selected {
                color: var(--color-primary);
            }

            &:hover,
            &:focus,
            &:active {
                outline: 0;
                color: var(--color-primary);
            }
        }
    }
}
