@import "../common";

.overlay {
    background-color: var(--color-overlay);
    bottom: 0;
    height: calc(100 * var(--vh));
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: opacity var(--transition-duration) var(--transition-curve-default);
    width: 100vw;

    &.active {
        opacity: 1;
        pointer-events: all;
    }
}
