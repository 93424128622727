.container {
    width: 100vw;
    height: calc(100 * var(--vh));
    display: flex;
    flex-flow: column;
    color: var(--color-text);
    background: var(--color-bg);
    overflow: hidden;
    position: relative;

    .background {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        filter: blur(10px);
        transform: scale(1.1);
        background: var(--color-overlay-main);
        background-position: center !important;
        background-size: cover !important;
        z-index: var(--z-base);

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: var(--color-overlay-main);
            z-index: var(--z-over);
        }
    }
}

.header,
.footer {
    z-index: var(--z-over);
}

.content { 
    z-index: var(--z-over);
    flex: 1 100%;
    overflow: auto;
}
