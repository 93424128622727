@import '../common';

// :root {
//     // --header-margin: calc(-1 * var(--spacing-md));
// }

.container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: var(--z-over-over);
    outline: 0;
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-overlay);
    z-index: var(--z-under);
    pointer-events: none;
}

.dialog {
    position: absolute;
    display: flex;
    flex-flow: column;
    background: black;
    color: white;
    border-radius: var(--border-radius-md);
    overflow: hidden;
    z-index: var(--z-over-over);
    touch-action: none;
}

.fullscreen {
    width: 96vw !important;
    height: calc(96 * var(--vh)) !important;
    max-width: 100% !important;

    @media (--viewport-mobile-xs) {
        border-radius: 0;
        max-height: calc(100 * var(--vh));
        max-width: 100vw;
        min-height: calc(100 * var(--vh));
        width: 100vw;
    }
}

.header {
    background: var(--dialog-color-header-bg);
    color: var(--color-text);
    display: flex;
    flex-flow: row;
    padding: var(--spacing-default);
}

.headerContent {
    display: flex;
    flex-flow: column;
    flex: 1;
    justify-content: flex-end;
}

.headerActions {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-end;
}

.addon {
    @include typo-heading-5;

    margin-top: var(--spacing-sm);
}

.title {
    @include typo-heading-5;

    margin: 0;
    font-weight: 600;
}

.subtitle {
    @include typo-small;

    margin: 0;
    font-weight: 300;
    text-transform: uppercase;
}

.content {
    padding: var(--spacing-md) var(--spacing-sm);
    overflow: auto;
    background: var(--color-bg-card);
    color: var(--color-text);
    border-bottom-left-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
    max-height: 100%;
    flex: 1;
}

.footer {
    padding: var(--spacing-sm);
    text-align: right;
}

.closeIcon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (--break-tablet) {
    .dialog {
        max-width: 600px;
        margin: 0 auto;
    }
}
