@import '../common';

.button {
    align-self: center;
    padding: 0 var(--spacing-sm);
    margin-right: var(--spacing-md);
    color: var(--color-table-text-summary) !important;
    font-size: 3.3rem;
    line-height: 3.3rem;
    display: flex;
    align-items: center;

    > span {
        cursor: pointer;
        padding: var(--spacing-xs);
        border-radius: 50%;
        transition: background-color var(--transition-2), color var(--transition-2);
    }
}


.modalButton {
    margin-right: var(--unit);
}