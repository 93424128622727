@mixin button-accent {
    cursor: pointer;
    color: var(--button-color-text);
    background: var(--color-accent-light);
}

@mixin button-primary {
    cursor: pointer;
    color: var(--color-primary);
}

@mixin button-accent-raised {
    cursor: pointer;
    color: var(--button-color-text);
    background-color: var(--color-accent);
}

@mixin button-primary-raised {
    cursor: pointer;
    color: var(--button-color-text);
    background-color: var(--color-primary);
}

@mixin reset {
    box-sizing: border-box;
    font-family: var(--preferred-font);
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased; //stylelint-disable-line
    text-size-adjust: 100%;

    & *,
    & *::after,
    & *::before {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased; //stylelint-disable-line
        text-size-adjust: 100%;
        -webkit-touch-callout: none;
    }
}
