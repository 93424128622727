@import '../common';

.logo {
    height: 100%;
    z-index: var(--z-over);
}

.circle {
    fill: var(--color-text);
}

.name {
    fill: var(--color-text);
}

:global(.dark) {
    .circle {
        fill: var(--color-text);
    }

    .name {
        fill: var(--color-text);
    }
}
