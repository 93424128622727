@import "../common";
@import "../components/Input.module.scss";

.container {
    @include typo-heading-4;

    display: flex;
    flex-flow: row;
    align-items: center;
    align-self: center;
    padding: 0 var(--spacing-sm);

    color: var(--color-table-text-summary);
    font-size: var(--button-floating-mini-font-size);
}

.searchInput {
    margin-right: 25px;

    input {
        transition: border-color var(--transition-duration) var(--transition-curve-default);
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input:valid {
        border: 1px solid var(--color-accent);

        ~ .icon {
            color: var(--color-text);
            background-color: var(--color-accent-light);
        }
    }
}

.icon {
    position: absolute;
    right: -32px;
    top: 50%;
    transform: translateY(-50%);
    left: auto;
    width: calc(2.8 * var(--unit));
    height: calc(2.8 * var(--unit));
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-table-text-summary);
    transition: color var(--transition-duration) var(--transition-curve-default);
    border-radius: 50%;
}
