@import '../common';

.container .autocomplete .input::after,
.container .dropdown .value::after {
    right: 40px;
}

.container {
    position: relative;
}

.container .clearIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    color: var(--color-text);
    cursor: pointer;
    transition: color var(--transition-2);

    &:focus,
    &:hover {
        color: var(--color-text-light);
    }
}

.inputElement[type='datetime-local'] ~ .label:not(.fixed) {
    font-size: 12px;
    top: 6px;
}

.input {
    @include reset;

    position: relative;
    margin: var(--spacing-sm) 0;

    &.withIcon {
        margin-left: calc(var(--input-icon-size) + var(--input-icon-right-space));
    }
}

.icon {
    color: var(--input-text-label-color);
    display: block;
    font-size: var(--input-icon-font-size) !important;
    height: var(--input-icon-size);
    left: calc(-1 * (var(--input-icon-size) + var(--input-icon-right-space)));
    line-height: var(--input-icon-size) !important;
    position: absolute;
    text-align: center;
    top: var(--input-icon-offset);
    transition: color var(--transition-duration) var(--transition-curve-default);
    width: var(--input-icon-size);
}

.inputElement {
    @include typo-normal;

    border-radius: var(--input-border-radius);
    border: none;
    background-color: var(--color-bg-card-invert-lighter);
    color: var(--input-text-input-element-color);
    display: block;
    outline: none;
    padding: var(--spacing-sm);
    width: 100%;

    &:required {
        box-shadow: none;
    }

    &:focus:not([disabled]):not([readonly]) {
        & ~ .bar::before,
        & ~ .bar::after {
            width: 50%;
        }

        & ~ .label:not(.fixed) {
            color: var(--input-text-highlight-color);
        }

        & ~ .hint {
            display: block;
            opacity: var(--input-hint-opacity);
        }

        &.filled ~ .hint {
            opacity: 0;
        }
    }

    &:focus:not([disabled]):not([readonly]),
    &.filled,
    &[type='date'],
    &[type='time'] {
        & ~ .label:not(.fixed) {
            @include typo-smallest;

            top: var(--input-focus-label-top);
        }
    }

    &.filled ~ .label.fixed,
    &.filled ~ .hint {
        display: none;
    }
}

.label {
    @include typo-normal;

    color: var(--input-text-label-color);
    pointer-events: none;
    transition-duration: var(--transition-duration);
    transition-property: font-size, color;
    transition-timing-function: var(--transition-curve-default);

    &.fixed ~ .hint {
        display: none;
    }

    &.error {
        color: var(--input-text-error-color);
    }
}

.labelBorder {
    margin-bottom: var(--spacing-sm);
    border-bottom: 1px solid var(--input-text-label-color);
}

.hint {
    @include typo-normal;

    color: var(--input-text-label-color);
    left: 0;
    opacity: var(--input-hint-opacity);
    pointer-events: none;
    position: absolute;
    top: calc(var(--input-padding) + 1.5 * var(--input-field-padding));
    transition-duration: var(--transition-duration);
    transition-property: opacity;
    transition-timing-function: var(--transition-curve-default);
}

.bar {
    display: none;
    position: relative;
    width: 100%;

    &::before,
    &::after {
        background-color: var(--input-text-highlight-color);
        bottom: 0;
        content: '';
        height: 2px;
        position: absolute;
        transition-duration: 0.2s;
        transition-property: width, background-color;
        transition-timing-function: var(--transition-curve-default);
        width: 0;
    }

    &::before {
        left: 50%;
    }

    &::after {
        right: 50%;
    }
}

span.error,
span.counter {
    @include typo-smallest;

    color: var(--input-text-error-color);
    line-height: var(--input-underline-height);
    margin-bottom: calc(-1 * var(--input-underline-height));
}

.counter {
    color: var(--input-text-label-color);
    position: absolute;
    right: 0;
}

.disabled > .inputElement {
    opacity: 0.6;
}

.errored {
    padding-bottom: 0;

    & > .inputElement {
        border-bottom-color: var(--input-text-error-color);
        margin-top: 1px;
    }

    & > .counter {
        color: var(--input-text-error-color);
    }    
}

.hidden {
    display: none;
}

.outerContainer {
    position: relative;

    .clearIcon {
        position: absolute;
        bottom: 14px;
        right: 14px;
        color: var(--color-text);
        cursor: pointer;
        transition: color var(--transition-2);
        background: none;
        box-shadow: none;
        padding: 0;

        &:focus,
        &:hover {
            color: var(--color-secondary);
        }
    }
}
